// LandingPage.js
import React, { useState } from 'react';
import './styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LandingPage = () => {
  const [instaUsername, setInstaUsername] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (instaUsername.trim() === '') {
      toast.error('Please enter your Insta @ before submitting the form');
      return;
    }

    const usernameRegex = /^[a-zA-Z0-9_.]+$/;
    if (!usernameRegex.test(instaUsername)) {
      toast.error('Invalid Insta @. Please use only letters, numbers, periods, and underscores.');
      return;
    }

    const formData = new FormData(e.target);
    toast.success('Form submitted successfully!');

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxnCrSq8oFwU7qKzBNJllWsk-bpSgZ8uLh-VHid5AM8GjDIZyPXdjhw73j86L3LI4qwSg/exec', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Error submitting form. Please check your network connection.');
    }
  };

  return (
    <div className="container">
      <div className="brand">gatedate</div>
      <div className="content">
        <h1>Sign up to meet your airport crush</h1>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="enter your insta @"
              name="Instagram"
              onChange={(e) => setInstaUsername(e.target.value)}
              required
            />
            <button type="submit">join the waitlist</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LandingPage;